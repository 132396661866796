@import "utils/theme.scss";

.create_user_form{
	h3{
		margin-top: 0;
	}

	label{
		width: 100%;

		span{
			color: #aaa;
		}
		input, select{
			@extend %generic-input;
			margin: 5px 0 20px 0px;

			&:invalid{
				outline: 1px solid rgb(255, 83, 83);
			}
		}
	}

	.submit_bitton_wrapper{
		display: flex;
		justify-content: flex-end;
		width: 100%;

		button{
			@extend %generic-button;
			background: $primary-light;
			min-width: 150px;
			margin-bottom: 10px;

			&:hover{
				background: $primary;
			}

			svg{
				// @extend %generic-button;
				margin-bottom: -5px;
				height: 24px;
				width: 24px;
			}
		}
	}
}

#popup-container-main.user_popup{
	.popup_main_content .popup_contents_outer{
		height: 80vh;
		max-height: 550px;
		max-width: 750px;

		label.reset_password_label{
			display: flex;
			align-items: center;
			height: 25px;
			grid-gap: 10px;
			
			input[type="checkbox"]{
				height: 25px;
				width: 25px;
				margin: 0px;
			}
		}
	}
}