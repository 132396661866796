@import "utils/theme.scss";

.search_user{
    position: relative;
    
    .group_focus{
        position: absolute;
        top: 76px;
        left: 0;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        background: #fff;
        z-index: 99;
        box-shadow: 0 0 15px 0 #ccc;
        border-radius: 10px;
        
        .loading_users{
            display: flex;
            padding: 20px;
            justify-content: center;
        }
        .search_user_not_found{
            text-align: center;
            padding: 20px;
        }
        .search_user_list{
            background: #fff;
            z-index: 2;
            
            .search_user_list_item{
                padding: 10px;
                cursor: pointer;
                transition: all .5s ease;
                &:hover{
                    background: #e5e5e5;
                }
            }
        }
    }
}