@import "utils/theme.scss";

#popup-container-main.reports_popup {

    .popup_contents_outer {
        max-width: 768px;
    }

    .reports_heading_heading {
        display: flex;
        justify-content: space-between;

        svg {
            cursor: pointer;
            width: 22px;
            height: 22px;
        }
    }

    & .reports_payments {

        & .table_headings,
        & .tr {
            grid-template-columns: 0.7fr .7fr .7fr .5fr .5fr;
        }
    }

    & .reports_outstanding {

        & .table_headings,
        & .tr {
            grid-template-columns: 0.7fr .7fr .7fr .5fr .5fr .5fr;
        }
    }

    & .reports_creditnotes {

        & .table_headings,
        & .tr {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

}