@import "../../utils/theme.scss";

.smalllink {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

// @mixin deleteIconBtn{
// 	button{
// 		margin-bottom: 10px;
// 		background: transparent;
// 		outline: none;
// 		padding: 0;
// 		border: 0;

// 		svg{
// 			width: auto;
// 			height: 20px;
// 			color: #f00;
// 		}
// 	}
// }

@mixin commonSectionStyle {
	margin-bottom: 20px;
	padding: 10px;
	background: #f5f5f5;
	border-radius: 5px;
	transition: all .5s ease;

	&:hover {
		background: #e5e5e5;
	}

	h4 {
		margin: 0 0 5px 0;
	}

	//@include deleteIconBtn;
	input,
	textarea {
		@extend %generic-input;
		height: 35px;
		padding: 5px 10px;
	}

	.list_section_header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}
}

#popup-container-main.quote_popup {
	.popup_main_content {
		justify-content: right;
	}

	.popup_contents_outer {
		// max-width: 1124px !important;
		width: 90%;
		border-radius: 0px;
		height: 100vh !important;
		max-height: 100vh;
		padding: 0 10px;

		.popup_contents_inner {
			height: 100vh;
			padding-bottom: 20px;
		}

		&>button.close_popup {
			// background: red;
			height: 30px;
			width: 30px;
			right: 3px;
			top: 0px;
		}
	}

	.related_invoices {

		.table_headings,
		.tr {
			grid-template-columns: 0.5fr 0.5fr 0.5fr 1.2fr 1fr 1fr 1fr 1fr 0.2fr;
		}

		a.tr {
			text-decoration: none;
			color: #333;
		}
	}

	.related_creditnotes {

		.table_headings,
		.tr {
			grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
		}

		& .table_body {
			& .tr {
				cursor: pointer
			}
		}

		a.tr {
			text-decoration: none;
			color: #333;
		}
	}

	.related_invoices_summery {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		margin: 20px 0;
		font-size: 18px;
		color: $primary;
		font-weight: bold;
	}
}

.quote_form {
	h3 {
		margin-top: 20px;
	}

	& .red {
		color: red;
	}

	& .inline {
		& div {
			display: inline;
		}
	}

	label {
		width: 100%;

		span {
			color: #aaa;
		}

		input {
			@extend %generic-input;
			margin: 5px 0 20px 0px;
		}
	}

	textarea {
		resize: vertical;
	}

	label.checkbox_label {
		display: flex;
		align-items: center;
		margin: -10px 10px 10px 0;
		width: max-content;

		input {
			margin: 0 10px 0 0;
			width: 20px;
			height: 20px;
		}
	}

	& .top_section {
		margin-top: 30px;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 30px;
		display: grid;
		grid-gap: 20px;

		& .customer_wrapper {
			& .customerdetails {
				padding: 10px;
				grid-template-columns: 1fr 1fr;
				margin-bottom: 30px;
				display: grid;
			}
		}

		input,
		textarea {
			width: 100%;
			@extend %generic-input;
			margin-top: 5px;
		}

		textarea {
			height: 150px;
		}
	}

	.quote_items_list_header {
		h5 {
			text-align: left;
			margin: 0 0 5px 0;
		}
	}

	.quote_items_list {
		& .quote_item {

			& .item_price,
			& .line_total {
				display: inline-block;
				font-size: 18px;
				line-height: 32px;
				text-align: left;

				& .currencysym {
					float: left;
					font-size: 25px;
				}

				& .taxrateid {

					height: 35px;
					padding: 5px 10px;
				}

				& input[name='net_amount'],
				& input[name='tax_amount'],
				& input[name='unit_price'],
				& input[name='line_total'] {
					float: right;
					width: 130px;
				}
			}

			& .line_total {

				font-size: 18px;
				line-height: 32px;
			}
		}
	}

	.quote_items {
		@include commonSectionStyle;

		.tri_columns {
			grid-template-columns: 2fr 0.5fr 1fr 0.7fr 0.8fr 1fr 0.3fr;
			margin-bottom: 10px;
		}
	}

	.quote_jobs {
		@include commonSectionStyle;

		.quote_job {
			gap: 10px;
			margin-bottom: 20px;
			border-bottom: 2px solid #ccc;
			align-items: end;
			grid-template-columns: 1fr 1fr 1fr .5fr;

			&:last-child {
				border-bottom: 0;
			}

			label {
				margin-bottom: 10px;

				input {
					margin-bottom: 0px;
					height: 35px;
					font-size: 14px;
				}

				&.in_stock {
					width: 100%;
					justify-content: right;
				}
			}

			.delete_job {
				text-align: right;
			}
		}
	}

	.quote_notes {
		@include commonSectionStyle;

		.quote_note {
			display: flex;
			grid-gap: 10px;
			align-items: flex-start;
			margin-bottom: 10px;

			textarea {
				height: 60px;
			}
		}
	}

	button {
		&.delete {
			margin-bottom: 10px;
			background: transparent;
			outline: none;
			padding: 0;
			border: 0;

			svg {
				width: auto;
				height: 20px;
				color: #f00;
			}
		}
	}

	button.add_to_list_btn {
		border-radius: 7px;
		padding: 7px 10px;
		background-color: #03a9f4;
		border: 0px;
		color: #fff;
		display: flex;
		align-items: center;

		svg {
			fill: #fff;
			color: #fff;
			margin: 0 5px 0 0;
		}
	}
}

/*View quote section design*/
.view_quote_details_container {
	margin-bottom: 30px;

	.section {
		margin-top: 40px;

		.section_title {
			margin: 0 0 5px 0;
			color: $primary;
			// font-size: 22px;
		}

		.table_headings {
			background: $primary-light;
		}

		.table {
			border: 1px solid #ccc;
		}
	}

	a {
		text-decoration: none;
		color: $primary-light;
		transition: .3s ease;

		&:hover {
			color: $primary;
		}
	}

	.quote_details {
		.header_section {
			margin-top: 10px;

			.section_title {
				margin: 0 0 10px 0;
			}

			.header {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 20px;

				margin: 0 0 20px 0;
				color: #555;

				p {
					margin: 7px 0;
				}
			}

			.table {
				border: 1px solid #ccc;

				.table_headings,
				.tr {
					grid-template-columns: 1fr 0.9fr .8fr 1.6fr .7fr 0.8fr 0.5fr;
				}
			}

			span.label_text {
				font-size: 17px;
				font-weight: bold;
			}
		}
	}
}

.quote_items_details {
	.bi_columns_title {
		justify-content: space-between;
		display: flex;

		span {
			font-size: 16px;
		}
	}

	.quote_items {

		.table_headings,
		.tr {
			grid-template-columns: 3fr 1fr 1fr 1fr .8fr;
		}
	}
}

.quote_jobs_details {
	.quote_jobs {

		.table_headings,
		.tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		}
	}
}

.quote_notes_details {
	.quote_notes {

		.table_headings,
		.tr {
			grid-template-columns: 5fr 1fr 1fr 1fr;
		}
	}
}

.quad_columns,
.tri_columns {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
}

.tri_columns {
	grid-template-columns: 1fr 1fr 1fr;
}