@import "utils/theme.scss";
.sidebar_container{
	height:  100vh;
	background: $primary;
	width: 100%;
	max-width: 250px;
	min-width:  175px;
	color:  #fff;
	position: relative;

	.sidebar_nav{
		width: 100%;
		max-width: 250px;
		min-width:  175px;
		overflow-y: scroll;
		height: calc(100vh - 70px);

		&::-webkit-scrollbar {
		  width: 0px;
		}
	}

	.logo_container{
		display: flex;
		justify-content: center;
		padding: 10px;
		align-items: center;
		grid-gap: 10px;

		.logo_name{
			margin: 0px;

			span{
				color: $primary-dim;
			}
		}
		.user_role{
			font-size: 13px;
		}
		img{
			
			max-width:100%
		}
	}

	.sidebar_pages_links{

		.welcome_user{
			text-align: center;
			padding: 0px 10px;
			color: $primary-dim;
		}

		a{
			display: flex;
			grid-gap: 10px;
			width: 100%;
			text-align: left;
			background: transparent;
			border: 0;
			outline: none;
			color:  #ededed;
			font-size: 16px;
			padding: 15px 10px;
			border-bottom:  1px solid #cccccc50;
			cursor: pointer;
			font-size: 16px;
			text-decoration: none;
			border-right: 4px solid transparent;
			transition: .3s all ease;

			svg{
				height: 21px;
				width: 21px;
				
				&, path{
					color: #fff;
					stroke: #fff;
				}
			}
			&.focused{
				background: #E35F1C80;
				border-right-color: #E35F1C;
			}
			
			&:hover, &.active{
				background: $secondary-dim;
				border-right-color: $secondary-light;
			}
		}
	}

	.sidebar_footer{
		position: absolute;
		height: 20px;
		left: 0;
		right: 0;
		bottom: 0;
	}
}