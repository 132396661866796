@import "utils/theme.scss";

.nav {
  height: 60px;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .bread-crumbs{
    color: #fff;
  }

  .right-nav-side{
    gap:  20px;
    display: flex;

    .navbar_right_icon_btns{
      display: flex;
      gap:  20px;

      &>button{
        display: flex;
        gap:  7px;
        align-items: center;
        cursor: pointer;
        background: transparent;
        border:  0px;
        color:  #fff;
        font-size:  16px;
        border: none;
        font-weight: bold;
      }
    }

  }
}