@import "utils/theme.scss";

#popup-container-main{
	&, .hidding_layout{
		position: fixed;
		z-index: 9;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: #00000060;
		backdrop-filter: blur(3px);
	}

	.hidding_layout{
		position: absolute;
		z-index: 0;
	}

	.popup_main_content{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;

		.popup_contents_outer{
			width: 100%;
			max-width: 80%;
			height: 100%;
			max-height: 90%;
			background: #fff;
			border-radius: 10px;
			padding: 20px;
			position: relative;
			
			.popup_contents_inner{
				overflow-y: auto;
				height: 100%;
				margin-right: -10px;
				padding-right: 10px;
				padding-left: 1px;

				&::-webkit-scrollbar {
				  width: 4px;
				}
				&::-webkit-scrollbar-track {
				  background: #f1f1f1;
				}
				&::-webkit-scrollbar-thumb {
				  background: #ccc;
				}
				&::-webkit-scrollbar-thumb:hover {
				  background: #555;
				}
			}

			button.close_popup{
				position: absolute;
				top: -15px;
				right: -15px;
				padding: 0px;
				background: transparent;
				outline: none;
				border: 0;

				svg{
					width: 30px;
					height: 30px;
					fill: #f00;
					background: #fff;
					border-radius: 100%;
				}
			}
		}
	}
}
