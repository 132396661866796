@import "../../utils/theme.scss";

.search_inventory{
    position: relative;
    
    select, input{
        @extend %generic-input;
        margin-top: 5px;
    }

    button{
        @extend %generic-button;
    }
    
    .group_focus{
        position: absolute;
        top: 36px;
        left: 0;
        width: 650px;
        max-height: 400px;
        overflow-y: auto;
        background: #fff;
        z-index: 99;
        box-shadow: 0 0 15px 0 #ccc;
        border-radius: 10px;
        
        .loading_inventorys{
            display: flex;
            padding: 20px;
            justify-content: center;
        }
        .search_inventory_not_found{
            & .grid{
                margin: 10px;
                display: flex;
                flex-direction: column;
                & .grid2{
                    display: flex;
                    padding: 0px;
                    justify-content: space-between;
                }
                & > div{
                    margin:10px;
                }
            }
            text-align: left;
            padding: 0px;
        }
        .search_inventory_list{
            background: #fff;
            z-index: 2;
            
            .search_inventory_list_item{
                padding: 10px;
                cursor: pointer;
                transition: all .5s ease;
                display: flex;
                grid-gap: 20px;
                line-break: anywhere;
                justify-content: space-between;

                &:nth-of-type(even){
                    background-color: #f5f5f5;
                }

                div{
                    margin-top: 5px;
                }
                span{
                    font-weight: bold;
                }

                &:hover{
                    background: #e5e5e5;
                }

                p{
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
    }
}

// #popup-container-main.quote_popup .popup_main_content .popup_main_content{
//     justify-content: center;
//     transform: scale(0.8);

//     .popup_contents_outer{
//         max-width: 700px !important;
//     }
// }