.rw-btn,.rw-btn:focus,.rw-list:focus,.rw-multiselect-tag-btn:focus,.rw-multiselect-tag:focus,.rw-time-part-meridiem :focus {
    outline: 0
}

.rw-btn,.rw-input {
    box-shadow: none;
    font: inherit
}

.rw-calendar-month .rw-cell,.rw-list-empty {
    text-align: center
}

.rw-btn {
    padding: 0;
    margin: 0;
    border: none;
    color: inherit;
    background: padding-box none;
    line-height: inherit;
    touch-action: manipulation;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.rw-picker-btn,.rw-picker-caret,.rw-widget {
    color: #212529
}

.rw-btn:disabled,.rw-state-disabled .rw-btn,.rw-state-readonly .rw-btn,fieldset[disabled] .rw-btn {
    cursor: inherit
}

.rw-listbox.rw-state-disabled>*,fieldset[disabled] .rw-btn,fieldset[disabled] .rw-listbox>* {
    pointer-events: none
}

.rw-calendar-btn:hover,.rw-cell:hover,.rw-picker-btn:disabled,.rw-picker-btn:hover,.rw-state-disabled .rw-widget-input,.rw-widget-input.rw-state-disabled,fieldset[disabled] .rw-picker-btn,fieldset[disabled] .rw-widget-input {
    background-color: #e9ecef
}

.rw-calendar-btn:active,.rw-cell:active,.rw-picker-btn:active {
    background-color: #dee2e6
}

.rw-input-addon,.rw-input-addon.rw-picker-btn {
    border-left: 1px solid #ccc
}

[dir=rtl] .rw-input-addon,[dir=rtl] .rw-input-addon.rw-picker-btn {
    border-right: 1px solid #ccc;
    border-left: none
}

.rw-sr {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0
}

.rw-widget {
    border: none;
    font-size: 1em;
    font-family: inherit;
    outline: 0;
    position: relative
}

.rw-widget,.rw-widget *,.rw-widget :after,.rw-widget :before,.rw-widget:after,.rw-widget:before {
    box-sizing: border-box
}

.rw-state-disabled,.rw-state-readonly,fieldset[disabled] .rw-widget {
    cursor: not-allowed
}

.rw-widget-picker {
    display: grid;
    overflow: hidden;
    min-height: 38px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: 0;
    grid-template: 1fr/1fr 1.9em;
    width: 100%
}

.rw-detect-autofill,.rw-dropdownlist-search,.rw-input {
    margin: 0;
    border: none;
    touch-action: manipulation;
    outline: 0
}

.rw-widget-picker.rw-hide-caret {
    grid-template-columns: 1fr
}

.rw-state-focus .rw-widget-picker {
    border-color: #80bdff;
    box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    transition: box-shadow .15s ease-in-out
}

.rw-listbox.rw-state-focus,.rw-state-focus .rw-widget-picker.rw-widget-input {
    box-shadow: 0 0 0 3px rgba(0,123,255,.25),inset 0 1px 1px rgba(0,0,0,.075)
}

.rw-input {
    color: inherit;
    background: none;
    line-height: inherit;
    padding: 0 .857em
}

.rw-detect-autofill:disabled,.rw-dropdownlist-search:disabled,.rw-input:disabled,.rw-multiselect-input:disabled,.rw-time-input.rw-state-focus .rw-time-input-clear.rw-show,.rw-time-input:hover .rw-time-input-clear.rw-show,.rw-time-part-input:disabled {
    opacity: 1
}

.rw-input[type=text]::-ms-clear {
    display: none
}

.rw-input:-moz-read-only {
    cursor: inherit
}

.rw-input:disabled,.rw-input:read-only {
    cursor: inherit
}

.rw-widget-input {
    color: #495057;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    background-clip: padding-box;
    width: inherit
}

.rw-input::-moz-placeholder {
    color: #999
}

.rw-input:-ms-input-placeholder {
    color: #999
}

.rw-input::placeholder,.rw-placeholder {
    color: #999
}

.rw-state-disabled .rw-input::-moz-placeholder {
    color: #999
}

.rw-state-disabled .rw-input:-ms-input-placeholder {
    color: #999
}

.rw-state-disabled .rw-input::placeholder,.rw-state-disabled .rw-placeholder {
    color: #999
}

.rw-detect-autofill,.rw-dropdownlist-search {
    padding: 0;
    color: inherit;
    box-shadow: none;
    background: none;
    font: inherit;
    line-height: inherit
}

.rw-detect-autofill:-webkit-autofill {
    -webkit-animation-name: react-widgets-autofill-start;
    animation-name: react-widgets-autofill-start;
    -webkit-animation-duration: .01ms;
    animation-duration: .01ms
}

.rw-detect-autofill:not(:-webkit-autofill) {
    -webkit-animation-name: react-widgets-autofill-cancel;
    animation-name: react-widgets-autofill-cancel;
    -webkit-animation-duration: .01ms;
    animation-duration: .01ms
}

.rw-input:-webkit-autofill,.rw-webkit-autofill .rw-widget-container {
    background-color: #e8f0fe!important;
    background-image: none!important;
    color: #000!important
}

.rw-list {
    font-size: 1em;
    overflow-x: visible;
    overflow-y: auto;
    padding-top: .5em;
    padding-bottom: .5em
}

.rw-list-option,.rw-list-option-create {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #212529;
    cursor: pointer;
    border: 1px solid transparent;
    background-clip: padding-box
}

.rw-list-option-create:hover,.rw-list-option:hover,[data-intent=keyboard] .rw-list-option.rw-state-focus:not(.rw-state-selected),[data-intent=keyboard] .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
    background-color: #e9ecef;
    border-color: #e9ecef
}

.rw-cell.rw-state-selected,.rw-list-option.rw-state-selected,.rw-state-selected.rw-list-option-create {
    background-color: #64c5b1;
    border-color: #64c5b1;
    color: #fff
}

.rw-list-option.rw-state-disabled,.rw-state-disabled.rw-list-option-create {
    cursor: not-allowed;
    background-color: transparent;
    border-color: transparent;
    color: #dee2e6
}

.rw-list-empty,.rw-list-optgroup,.rw-list-option,.rw-list-option-create {
    padding: .25em 1.5em;
    outline: 0
}

.rw-list-empty {
    color: #ced4da
}

.rw-multiselect-input,.rw-multiselect-tag-btn {
    color: inherit;
    touch-action: manipulation;
    outline: 0
}

.rw-list-optgroup {
    font-weight: 700;
    padding-top: 7px
}

.rw-list-option-create {
    display: block;
    font-size: 1em;
    padding: .25em 1.5em
}

.rw-calendar-contained,.rw-listbox {
    border-radius: 4px;
    background-color: #fff;
    /* border: 1px solid #ccc */
}

.rw-listbox.rw-state-focus {
    border-color: #80bdff;
    transition: box-shadow .15s ease-in-out
}

.rw-dropup .rw-popup,.rw-popup {
    box-shadow: 0 .5em 1em rgba(0,0,0,.175)
}

.rw-listbox.rw-state-readonly>* {
    cursor: inherit
}

.rw-popup-container {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: -1em;
    right: -1em;
    padding: 0 1em
}

.rw-popup-container.rw-dropup {
    top: auto;
    bottom: 100%
}

.rw-state-focus .rw-popup-container {
    z-index: 3
}

.rw-slide-transition {
    margin-bottom: 1em;
    transition: transform .13s,opacity .1s
}

.rw-dropup>.rw-slide-transition {
    margin-bottom: 0;
    margin-top: 1em
}

.rw-popup {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 2px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff
}

.rw-popup .rw-list {
    max-height: 250px
}

.rw-dropup .rw-popup {
    margin-top: 0;
    margin-bottom: 2px
}

.rw-calendar,.rw-slide-transition-entering,.rw-slide-transition-exited,.rw-slide-transition-exiting {
    overflow: hidden
}

.rw-slide-transition-entering .rw-slide-transition {
    transform: translateY(0);
    opacity: 1;
    transition-timing-function: ease-out
}

.rw-slide-transition-exiting .rw-slide-transition {
    transition-timing-function: ease-in
}

.rw-slide-transition-exited .rw-slide-transition,.rw-slide-transition-exiting .rw-slide-transition {
    opacity: 0;
    transform: translateY(-10%)
}

.rw-slide-transition-exited.rw-dropup .rw-slide-transition,.rw-slide-transition-exiting.rw-dropup .rw-slide-transition {
    opacity: 0;
    transform: translateY(10%)
}

.rw-slide-transition-exited {
    display: none
}

.rw-dropdown-list {
    width: 100%
}

.rw-dropdown-list-input {
    padding: 0 .857em;
    align-self: center;
    display: grid;
    min-width: 0;
    grid-template: 1fr/1fr
}

.rw-dropdown-list-input>* {
    grid-area: 1/1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

[dir=rtl] .rw-dropdown-list-input {
    padding-right: .857em;
    padding-left: 0
}

.rw-combobox-input {
    background-color: transparent;
    z-index: 1
}

.rw-multiselect-input {
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    font: inherit;
    line-height: inherit;
    height: 36px;
    padding: 0 .857em;
    max-width: 100%
}

.rw-multiselect-input:-moz-read-only {
    cursor: inherit
}

.rw-multiselect-input:disabled,.rw-multiselect-input:read-only {
    cursor: inherit
}

.rw-multiselect-taglist {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    outline: 0;
    cursor: text
}

.rw-state-disabled .rw-multiselect-taglist {
    cursor: unset
}

.rw-multiselect-tag {
    display: inline-flex;
    margin-left: 3.37px;
    margin-top: 3.37px;
    min-height: 29.26px;
    border-radius: 3px;
    background-color: #dee2e6;
    border: 1px solid #dee2e6;
    cursor: default;
    max-width: 100%;
    align-items: center;
    overflow: hidden
}

[dir=rtl] .rw-multiselect-tag {
    margin-left: 0;
    margin-right: 3.37px;
    padding: 0 .35em
}

.rw-calendar-btn:focus,.rw-cell:focus,.rw-multiselect-tag.rw-state-focus {
    z-index: 1;
    box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    transition: box-shadow .15s ease-in-out
}

.rw-multiselect-tag.rw-state-disabled,fieldset[disabled] .rw-multiselect-tag {
    opacity: .65
}

.rw-multiselect-tag-label {
    padding: 0 .35em
}

.rw-multiselect-tag-btn {
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    font: inherit;
    line-height: inherit;
    cursor: pointer;
    align-self: stretch;
    padding: 0 .35em
}

.rw-multiselect-tag-btn:disabled {
    cursor: inherit
}

.rw-calendar-popup {
    right: auto;
    min-width: 0;
    width: 22em
}

.rw-calendar-btn-view,.rw-calendar-now {
    font-weight: 700
}

.rw-calendar-header {
    display: flex;
    padding: .8em
}

.rw-calendar-btn,.rw-cell {
    background-clip: padding-box
}

[data-intent=mouse] .rw-calendar-btn:focus,[data-intent=mouse] .rw-cell:focus {
    z-index: auto;
    box-shadow: none
}

.rw-calendar-btn.rw-state-disabled,.rw-calendar-btn:disabled,.rw-cell.rw-state-disabled,.rw-cell:disabled {
    pointer-events: none;
    opacity: .35
}

.rw-calendar-btn {
    line-height: 2em;
    border-radius: 4px
}

.rw-calendar-btn-left,.rw-calendar-btn-right,.rw-calendar-btn-today,.rw-calendar-btn-view {
    padding: .3em .6em
}

[dir=rtl] .rw-calendar-btn-left,[dir=rtl] .rw-calendar-btn-right {
    transform: scaleX(-1)
}

.rw-calendar-btn-view>:last-child {
    margin: 0 .5em
}

.rw-calendar-btn-view+* {
    margin-left: auto
}

[dir=rtl] .rw-calendar-btn-view+* {
    margin-left: 0;
    margin-right: auto
}

.rw-calendar-grid {
    display: table;
    outline: 0;
    height: 14em;
    padding: 3px;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background-color: #fff
}

.rw-calendar-head {
    display: table-header-group
}

.rw-calendar-body {
    display: table-row-group
}

.rw-calendar-row {
    display: table-row;
    margin-bottom: 10px;
}

.rw-head-cell {
    border-bottom: 1px solid #ccc
}

.rw-cell,.rw-head-cell {
    display: table-cell;
    text-align: center;
    padding: .25em
}

.rw-cell {
    color: #212529;
    vertical-align: middle;
    border-radius: 4px;
    cursor: pointer;
    line-height: normal;
    border: 1px solid transparent;
    outline: 0
}

.rw-cell-off-range {
    color: #ced4da
}

.rw-calendar-transition-group {
    position: relative;
    overflow: hidden
}

.rw-calendar-transition {
    width: 100%;
    overflow: hidden;
    transition: transform .2s ease-in-out 0ms;
    transform: translate(0)
}

.rw-calendar-transition-next {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.rw-calendar-transition-bottom .rw-calendar-transition-prev,.rw-calendar-transition-top .rw-calendar-transition-next {
    transform: translateY(-100%)
}

.rw-calendar-transition-bottom .rw-calendar-transition-next,.rw-calendar-transition-top .rw-calendar-transition-prev {
    transform: translateY(100%)
}

.rw-calendar-transition-left .rw-calendar-transition-prev,.rw-calendar-transition-right .rw-calendar-transition-next {
    transform: translate(-100%)
}

.rw-calendar-transition-left .rw-calendar-transition-next,.rw-calendar-transition-right .rw-calendar-transition-prev {
    transform: translate(100%)
}

.rw-calendar-transition-next.rw-calendar-transition-active {
    transform: translate(0)
}

.rw-calendar-transition-prev {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    top: 0
}

@supports(transform-style: preserve-3d) {
    .rw-calendar-transition,.rw-calendar-transition-next.rw-calendar-transition-active {
        transform:translateZ(0)
    }

    .rw-calendar-transition-bottom .rw-calendar-transition-prev,.rw-calendar-transition-top .rw-calendar-transition-next {
        transform: translate3d(0,-100%,0)
    }

    .rw-calendar-transition-bottom .rw-calendar-transition-next,.rw-calendar-transition-top .rw-calendar-transition-prev {
        transform: translate3d(0,100%,0)
    }

    .rw-calendar-transition-left .rw-calendar-transition-prev,.rw-calendar-transition-right .rw-calendar-transition-next {
        transform: translate3d(-100%,0,0)
    }

    .rw-calendar-transition-left .rw-calendar-transition-next,.rw-calendar-transition-right .rw-calendar-transition-prev {
        transform: translate3d(100%,0,0)
    }
}

.rw-number-picker-spinners,.rw-popup {
    display: flex;
    flex-direction: column
}

.rw-popup>.rw-time-input {
    align-self: center;
    margin: 1em 0
}

.rw-time-input {
    display: inline-flex;
    min-height: 38px;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: 0;
    cursor: text
}

.rw-time-part-input,.rw-time-part-meridiem {
    margin: 0;
    border: none;
    color: inherit;
    box-shadow: none;
    background: none;
    touch-action: manipulation;
    padding: 0 .5em;
    outline: 0
}

.rw-time-part-input {
    font: inherit;
    line-height: inherit
}

.rw-time-part-input::-moz-placeholder {
    text-align: center
}

.rw-time-part-input:-ms-input-placeholder {
    text-align: center
}

.rw-time-part-input,.rw-time-part-input::placeholder {
    text-align: center
}

.rw-time-part-meridiem {
    font: inherit;
    line-height: inherit;
    text-transform: lowercase;
    font-variant: small-caps
}

.rw-time-input-clear {
    padding: 0 .4em;
    outline: 0;
    opacity: 0;
    margin-left: auto
}

[dir=rtl] .rw-time-input-clear {
    margin-left: revert;
    margin-right: auto
}

.rw-number-picker-spinners .rw-btn {
    flex: 1 1 0
}

:root {
    --ifm-color-primary: #028fcc;
    --ifm-color-primary-dark: #0281b8;
    --ifm-color-primary-darker: #027aad;
    --ifm-color-primary-darkest: #01648f;
    --ifm-color-primary-light: #029de0;
    --ifm-color-primary-lighter: #02a4eb;
    --ifm-color-primary-lightest: #0fb5fd;
    --ifm-color-info: #913d88;
    --ifm-color-info-dark: #83377a;
    --ifm-color-info-darker: #7b3474;
    --ifm-color-info-darkest: #652b5f;
    --ifm-color-info-light: #9f4396;
    --ifm-color-info-lighter: #a7469c;
    --ifm-color-info-lightest: #b755ad;
    --ifm-code-font-size: 95%
}

.docusaurus-highlight-code-line {
    background-color: #484d5b;
    display: block;
    margin: 0 calc(var(--ifm-pre-padding)*-1);
    padding: 0 var(--ifm-pre-padding)
}

.header-github-link:hover {
    opacity: .6
}

.header-github-link:before {
    content: "";
    width: 24px;
    height: 24px;
    display: flex;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12'/%3E%3C/svg%3E") no-repeat
}

html[data-theme=dark] .header-github-link:before {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12'/%3E%3C/svg%3E") no-repeat
}
