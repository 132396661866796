@import "../../utils/theme.scss";

.creditnote_popup{
	& .popup_contents_inner{
		margin-top:20px;
	}
}
#popup-container-main.creditnote_popup{
	z-index: 11;

	& .title{
		grid-template-columns: 1fr 1fr;
		margin-bottom: 30px;
		display: grid;
		& .subtitle{
			text-align:left;
			& h3{
				font-size:32px;
				margin-top:0px;
				margin-top:0px;
				&.void{
					color:#ff0000;
				}
			}
		}
		
	  }

	.popup_main_content .popup_contents_outer{
		max-width: 1500px;
		width:90vw;
	}

	&.voiding_creditnote_popup .popup_main_content{
		height: max-content;
		margin-top: 15%;

		.popup_contents_outer{
			max-width: 500px;
		}
	}

	h3{
		color: $primary-light;
		margin-bottom: 5px;
	}

	.creditnote_items {
		margin-bottom: 15px;

		.table_headings, .tr{
			grid-template-columns: 0.15fr 3fr 1.5fr .5fr .5fr 0.5fr 1.2fr .5fr .5fr .15fr;
			gap: 10px;
			font-size: 12px;
			align-items: center;
			padding: 3px 0px 3px 10px
		}
	}
	select, textarea, input{
		width: 100%;
		resize: vertical;
		height: 100px;
		@extend %generic-input;
		padding: 5px;
		height: 40px;
		font-size: 12px;
		background: #fff;
	}

	.form_button_wrapper{
		justify-content: end;
	}

	.creditnote_dates{
		margin-bottom: 20px;

		input {
			@extend %generic-input
		}
	}

	.creditnote_void_buttons{
		text-align: right;

		button{
			display: inline-block;

			&.success{
				margin-left: 20px;
			}
		}
	}

	.creditnote_description textarea{
		height: 100px;
		margin-bottom: 20px;
	}

	.void_note_form{
		position: fixed;
		width: 100%;
		height: 100%;
		background: #000000cc;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		
		form{
			background: #fff;
			padding: 15px;
			border-radius: 15px;

			.buttons{
				margin-top: 15px;
			}
			textarea{
				height: 50px;
			}

			h2{
				margin: 15px 0;
			}
		}
	}

	.record_payment_container{
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #000000aa;
		backdrop-filter: blur(5px);

		form{
			background-color: #fff;
			padding: 15px;
			max-width: 500px;
			margin: 0 15px;
			border-radius: 15px;
			display: flex;
			flex-direction: column;

			label{
				margin-bottom: 15px;
			}
		}
	}
}