@import "utils/theme.scss";

.create_event_form{
	h3{
		margin-top: 0;
	}
	
	label{
		width: 100%;
		
		span{
			color: #aaa;
		}
		input{
			@extend %generic-input;
			margin: 5px 0 20px 0px;
		}
	}
	
	.submit_bitton_wrapper{
		display: flex;
		justify-content: flex-end;
		width: 100%;
		
		button{
			@extend %generic-button;
			background: $primary-light;
			min-width: 150px;
			margin-bottom: 10px;
			
			&:hover{
				background: $primary;
			}
			
			svg{
				// @extend %generic-button;
				margin-bottom: -5px;
				height: 24px;
				width: 24px;
			}
		}
	}
}

.event_details, .cus_shipping_details{
	h4{
		margin-bottom: 20px;
	}
	p{
		margin: 0 0 15px 0;
	}
}
.cus_shipping_details{
	margin-top: 20px;
}

#popup-container-main.event_popup{
	.popup_main_content .popup_contents_outer{
		height: 80vh;
		max-height: 730px;
		max-width: 750px;
	}
	label.checkbox_label{
		margin-bottom: 20px;
		height: 40px;
		margin-top: 5px;
		display: flex;
		grid-gap: 10px;
		align-items: center;
		
		span{
			font-size: 16px;
		}

		input{
			width: 20px;
			height: 20px;
			margin: 0;
		}
	}
	
	textarea, select{
		@extend %generic-input;
		// height: 40px;
		margin: 5px 0 20px 0;
		background-color: #fff;
	}
	textarea{
		height: 200px;
		margin-bottom: 0;
		resize: none;
	}
}