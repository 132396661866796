$padding :15px;

#printDocument {
  display: none;
}

.quotetable {
  & .quotejobinvoicedetails {
    text-align: right;
  }
}

/*****PRINT TABLES ***/
.printdiv {
  padding: 50px;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;

  & h4 {
    margin: 0px;
    padding: 0px;

  }

  & .wrap_right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  & .table {
    margin-top: 20px;
    padding: 10px;
    // border:1px solid $primary;
    margin-bottom: 20px;
    display: grid;

    &.tcs,
    &.pricesummarybox {
      border: 0px;
    }

    & .logo {
      max-width: 250px;
      margin-bottom: 20px;
    }

    & .thead {
      & .td {
        font-weight: bold;
        ;
      }
    }

    &.pricesummarybox {
      & .tr {
        font-size: 18px;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
      }
    }

    &.paymentssummary {
      border-top: 1px solid #000;
      padding-top: 20px;
      font-size: 18px;
      justify-content: end;

      & .thead,
      & .tr,
      & .tbody .tr {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
      }
    }

    & .customerdetails {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }

    &.payments {

      & .thead,
      & .tr,
      & .tbody .tr {
        display: grid;
        gap: 20px;
        grid-template-columns: 2.7fr 1fr 1fr 1fr;
      }
    }

    &.quotetable,
    &.invoicetable {
      border: 0px;

      & .tr {
        &.header {
          display: grid;
          gap: 50px;
          grid-template-columns: 1.4fr 1fr
        }
      }

      & .quoteitems {
        & .tbody {
          & .tr {
            padding-top: 5px;
            padding-bottom: 5px;

            &:not(:last-child) {
              border-bottom: 1px solid #676767;
            }
          }
        }

        & .thead,
        & .tr,
        & .tbody .tr {
          display: grid;
          gap: 20px;
          grid-template-columns: 2.7fr 0.5fr 0.7fr;
          margin-bottom: 10px;
        }

        &.vat {

          & .thead,
          & .tr,
          & .tbody .tr {
            grid-template-columns: 2.7fr 0.5fr 0.7fr 0.7fr 0.7fr;
          }
        }
      }
    }



  }

  &>table {
    width: 100%;
  }

  & .pricesummarybox {
    max-width: fit-content
  }

  & .page-break {
    clear: both;
    page-break-before: always;
  }

  & .quoteitems {
    width: 100%;

    & .h3heading {
      font-size: 20pxt !important;
      font-weight: bold;
    }

    & .h4heading {
      font-size: 13pt !important;
    }

    & table.mobilequoteitems {
      display: none
    }

    & table.quoteitems {
      padding-top: $padding;
      @extend .printtable;

      & td {
        padding: 2px;

        &.desc {
          width: 30%
        }

        &.qty {
          width: 5%
        }

        &.itemprice {
          width: 5%
        }

        &.linetotal {
          width: 5%
        }
      }
    }


  }

  & .quotenotes {
    margin-top: 20px;
    padding: $padding;
  }

  & .tcs {
    padding-top: $padding;
    page-break-after: always;

  }
}

.printtabledetails {
  width: 100%;

  & td {
    font-size: 13px;
  }
}

.customerdetails {
  width: 100%;

  & table {
    @extend .printtable;
  }
}

table {

  & .col-logo,
  & .col-customeraddress {
    width: 70%;
    padding-bottom: $padding;

    & img.logo {
      width: 250px;

      display: block;
    }
  }
}

.border {
  background-color: #fff;
  border: 2px solid #1c2c42;
}

.printtable {
  width: 100%;
  padding: 10px;



  & td {
    font-size: 13px;
  }

  & .col-customeraddress,
  & .col-customername {
    padding-bottom: $padding;
  }

  & .col-quotedata {
    text-align: right;
    vertical-align: top;
  }

  & .col-customername {
    text-align: left;
  }

  & .col-quotedata,
  & .col-customername {
    width: 30%;

    & .quoteinvoicenumber {
      font-size: 20px;
      padding-top: 15px;
    }
  }



}

@media print {

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
  }

  #printDocument {
    display: block !important;
  }

  img.printlogo {
    width: 250px;
  }

  .printdiv {
    & .h3heading {
      font-size: 20pxt !important;
      font-weight: bold;
    }

    & .h4heading {
      font-size: 13pt !important;
    }
  }

  .quotepricessummary {
    text-align: right;

    & table {
      width:fit-content {
        text-align: right
      }
    }
  }
}