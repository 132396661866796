@import "../../utils/theme.scss";

.search_customer{
    position: relative;
    
    .search_add_customer{
        display: block;
        cursor: pointer;
        position: absolute;
        top: 35px;
        right: 10px;
        font-size: 20px;
        color: $primary-light;

    }
    
    .group_focus{
        position: absolute;
        top: 76px;
        left: 0;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        background: #fff;
        z-index: 99;
        box-shadow: 0 0 15px 0 #ccc;
        border-radius: 10px;
        
        .loading_customers{
            display: flex;
            padding: 20px;
            justify-content: center;
        }
        .search_customer_not_found{
            text-align: center;
            padding: 20px;
        }
        .search_customer_list{
            background: #fff;
            z-index: 2;
            
            .search_customer_list_item{
                padding: 10px;
                cursor: pointer;
                transition: all .5s ease;

                &:nth-of-type(even){
                    background-color: #f5f5f5;
                }

                div{
                    margin-top: 5px;
                }
                span{
                    font-weight: bold;
                }

                &:hover{
                    background: #e5e5e5;
                }

                p{
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
    }
}

// #popup-container-main.quote_popup .popup_main_content .popup_main_content{
//     justify-content: center;
//     transform: scale(0.8);

//     .popup_contents_outer{
//         max-width: 700px !important;
//     }
// }