@import "../../utils/theme.scss";


#jobs_page_container{
    
    .jobs_page_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0px;

        button{
            @extend %generic-button;
            background: $secondary-light;
        }
        
        input{
            @extend %generic-input;
            @extend %search-filter-input;
        }

        h2{
            margin-bottom: 0px;
        }
    }

    .table{
        &.jobs{
            & .table_headings, .tr{
                grid-template-columns:  0.7fr 1.2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr !important
            }
            & .table_body{
                & .tr{
                    & .td{
                        & span{
                            padding: 5px;
                                border-radius: 5px;
                                display: inline-block;
                                width: 100px;
                                text-align: center;
                            &.outofstock{
                                
                                background: #ff0000;
                                color: #fff;
                            }
                            &.instock{
                                
                                background: green;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

}

.slidein_right{
    animation: slideInRight .3s 1 ease;
}

@keyframes slideInRight {
    from{
        left: 100%;
        opacity: 0;
    }to{
        left: 0;
        opacity: 1;
    }
}