@import "../../utils/theme.scss";



.page_dashboard{
    background: #f3f4f3;

    .widget{
        display: block;
        background-color: #fff;
        margin-bottom: 20px;;
        border-radius: 5px;
        padding: 20px;
        position: relative;

        .widget_title{
            font-size: 16px;
        }

        .preloader{
            height: 50px;
        }
        
        .table.outofstockjobs{
            .table_headings, .tr{
                grid-template-columns: 0.7fr  1fr 0.7fr 0.7fr 0.7fr;
            }
            // .table_body{
            //     .tr.loop_item{
            //         grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));   
            //     }
            // }
           
        }
        .table.invoices{
            .table_headings{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            // .table_body{
            //     .tr.loop_item{
            //         grid-template-columns: repeat(auto-fill, minmax(93px, 1fr));    
            //     }
            // }
        }
        .big_text_number{
            color: #14256a;
            font-family: nunito,sans-serif;
            font-weight: 900;
            line-height: 1.2;
            font-size: 45px;
            text-align: center;
            display: block;
        }

        .loop_item{
            transition: all .3s ease;
            padding: 7px 5px;
            cursor: pointer;
            border-bottom: 1px solid #ccc;
            
            &:hover{
                background: #f3f4f3;
            }

            &:last-of-type{
                border-bottom: 0px;
                margin-bottom: 15px;
            }

            &.activity, &.message{
                display: flex;
                justify-content: space-between;
                width: 100%;
                gap: 7px;
                align-content: center;
                align-items: center;
                
                & div{
                    &.activity_title{
                        width:250px;
                    }
                    &.created_at{
                        width:200px;
                        font-size: 11px;
                        color: #666;
                        text-align: right;
                        margin-top: 5px;
                    }
                }
               
                svg{
                    fill: red;
                }
            }
            &.message{
                span{
                    text-align: left !important;
                }
                p{
                    margin: 5px 0 0 0;
                }
            }
        }
        .link.show_all {
            position: absolute;
            right: 15px;
            bottom: 10px;
            font-size: 12px;
            cursor: pointer;
            color: $primary;
            transition: all 0.3s ease;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
                color: $primary-light;
            }
        }
    }

    .dashboard_container{
        padding-left: 15px;
        margin-top: 30px;

        .odds_summary{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
            margin-bottom: 20px;

            .widget{
                cursor: pointer;
                transition: all .4s ease;
                margin-bottom: 0;

                &:hover{
                    transform: scale(1.05);
                    box-shadow: 0px 0px 30px 0px #ccc;
                }
            }
        }

        .statistics_section{
            display: grid;
            grid-template-columns: 2.5fr 1fr;
            grid-gap: 20px;            
        }

        .reports{

            form{
                display: grid;
                grid-template-columns: 1fr 1fr  1fr  .3fr;
                grid-gap: 20px;
                align-items: flex-end;

                label{
                    width: 100%;
                    font-size: 14px;
                    span{
                        color: #888;
                    }
                    input{
                        width: 100%;
                        font-size: 16px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        padding: 15px;
                        margin-top: 5px;
                        transition: all .3s ease;

                        &:focus{
                            outline: none;
                            box-shadow: 0px 0px 30px 0px #ccc;
                        }
                    }
                }

                button{
                    @extend %generic-button;
                    height: 50px;
                }
            }
        }

        .recent_stuff{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 20px;
            margin: 20px 0px;

            .widget{
                margin-bottom: 0px;
            }

            .request_help{
                form{
                    display: grid;
                    // grid-template-columns: 1fr 1fr .3fr;
                    grid-gap: 20px;
                    align-items: flex-end;
    
                    label{
                        width: 100%;
                        font-size: 14px;
                        span{
                            color: #888;
                        }
                        input, textarea{
                            width: 100%;
                            font-size: 16px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            padding: 10px;
                            margin-top: 5px;
                            transition: all .3s ease;
                            resize: vertical;
    
                            &:focus{
                                outline: none;
                                box-shadow: 0px 0px 30px 0px #ccc;
                            }
                        }
                        textarea{
                            height: 150px;
                        }
                    }
    
                    button{
                        @extend %generic-button;
                        height: 50px;
                    }
                }
            }
        }
    }
}