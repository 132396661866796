@import "utils/theme.scss";

.create_customer_form {
	&>div {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr 1fr;
	}

	h3 {
		margin-top: 0;
	}

	label {
		width: 100%;

		span {
			color: #aaa;
		}

		input {
			@extend %generic-input;
			margin: 5px 0 20px 0px;

			&.error {
				border: 1px solid red;
			}
		}
	}

	.submit_bitton_wrapper {
		display: flex;
		justify-content: flex-end;
		width: 100%;

		button {
			@extend %generic-button;
			background: $primary-light;
			min-width: 150px;
			margin-bottom: 10px;

			&:hover {
				background: $primary;
			}

			svg {
				// @extend %generic-button;
				margin-bottom: -5px;
				height: 24px;
				width: 24px;
			}
		}
	}
}

.cus_personal_details,
.cus_shipping_details {
	h4 {
		margin-bottom: 20px;
	}

	p {
		margin: 0 0 15px 0;
	}
}

.cus_shipping_details {
	margin-top: 20px;

	p {
		cursor: pointer;
	}
}

.bi_columns {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

#popup-container-main.customer_popup {

	.personal_details_header {
		display: flex;
		justify-content: space-between;
	}

	.new_quote_button {
		@extend %generic-button;
		padding: 10px 20px;
	}
}