@import "utils/theme.scss";

#popup-container-main.messages_popup{
    .popup_contents_outer{
        max-width: 500px;

        .new_message_form{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 15px;
            margin-bottom: 20px;

            label, textarea, button{
                display: block;
                width: 100%;
            }
            button{
                @extend %generic-button;
                max-width: 130px;
                display: flex;
                gap: 10px;
                padding: 15px 20px;
                justify-content: center;

                svg {
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    path{
                        stroke: #fff;
                    }
                }
            }
            textarea{
                @extend %generic-input;
                resize: vertical;
                height: 50px;
                font-size: 12px;
                padding: 6px;
            }
        }

        .message_item{
            display: flex;
            grid-gap: 10px;
            padding: 10px 0px;
            border-bottom: 1px solid #e6e6e6;
            transition: all 0.3s ease;
            
            &:hover{
                background-color: #f5f5f5;
            }

            div{
                width: 100%;

                span{
                    display: block;
                    text-align: right;
                    color: #666;
                    font-size: 12px;
                    margin-top: 5px;
                }

                p{
                    margin: 0px;
                }
            }
            svg{
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
        }
    }
}