@import "utils/theme.scss";

.user_page_container{
    
    h1{
        margin: 0;
    }
    .user_page_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0;

        button{
            @extend %generic-button;
            background: $secondary-light
        }

        .filter_search{
            @extend %generic-input;
            @extend %search-filter-input;
        }
    }
    .table .table_headings, .table .table_body .tr{
        grid-template-columns: .7fr 1.6fr .7fr 1fr 1fr 0.7fr 0.5fr 0.5fr .6fr;
        word-break: break-all;
    }
}