@import "../../utils/theme.scss";

#popup-container-main.invoice_popup {


	& .themebutton {

		@extend %generic-button;
		display: block;
		margin-left: auto;

		&.sm-left {
			padding: 5px 10px;
			font-size: 15px;
			float: left;
		}

	}


	z-index: 11;

	a {
		cursor: pointer
	}

	& svg {
		color: #fff;
	}

	& .popup_main_content {
		justify-content: center !important;
	}

	& .invoicetitle {
		grid-template-columns: 1fr 1fr;
		margin-bottom: 30px;
		display: grid;

		& .void.red {
			text-align: right;
			font-size: 40px;
		}
	}

	& .popup_main_content .popup_contents_outer {
		// max-width: 760px;
		width: 80vw;
		max-height: 90%;

		background: #fff;
		border-radius: 10px;
		padding: 20px;
		position: relative;

		& .textareawrapper {
			outline: 1px solid transparent;
			border: 1px solid #ddd;

			font-size: 18px;
			border-radius: 7px;
			padding: 10px 15px;
			transition: all 0.3s ease;
			margin-bottom: 50px;

			& .title {
				margin-bottom: 20px
			}
		}

		& .popup_contents_inner {
			overflow-y: auto;
			height: 100%;
			margin-right: -10px;
			padding-right: 10px;
			padding-left: 1px;
		}
	}

	&.voiding_invoice_popup .popup_main_content {
		height: max-content;
		margin-top: 15%;

		.popup_contents_outer {
			max-width: 500px;
		}
	}

	h3 {
		color: $primary;
		margin-bottom: 5px;

		&.red {
			color: #ff0000
		}
	}

	.invoice_items {
		margin-bottom: 15px;

		input[type="text"],
		input[type="number"],
		select {
			@extend %generic-input
		}

		.table_headings, .tr {
			grid-template-columns: 0.3fr 2fr 1fr 1fr 1fr 1fr 1fr;
		}

		& .tr.newitem .td.taxrateid {
			grid-column: span 2;

		}
	}

	a.confirm_remove {
		color: red;

		cursor: pointer;
	}

	.remove_icon {
		color: red;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}

	.add_icon {
		color: green;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}

	textarea {
		width: 100%;
		resize: vertical;
		height: 100px;
		margin-bottom: 20px;
		border: 0px
	}

	.payment_method {
		height: auto;
		padding: 5px 10px;
	}


	.form_btns {
		margin-top: 15px;
		display: flex;
		justify-content: right;
		gap: 20px;

		button {
			margin: 0;
		}

		button.email_invoice {
			background-color: #79554A;
		}

		button.update_invoice {
			path {
				stroke: #fff;
			}
		}

		button.print {
			background: #03a9f4;
		}

		button.done {
			background: #1BBC9A;
		}
	}

	.add_invoice_lines {
		// display: flex;
		// grid-gap: 20px;
		margin: 20px 0px;
		// align-items: flex-end;


		button {
			padding: 0 10px;
			line-height: 12px;
			font-size: 15px;
			min-width: 120px;
		}

		input,
		button {
			height: 30px;
		}
	}

	.new_invoice_lines {
		margin-bottom: 20px;

		.table_headings,
		.tr {
			grid-template-columns: 2fr .5fr .1fr;
		}
	}

	.invoice_dates {
		margin-bottom: 20px;
		grid-template-columns: 1fr 1fr 1fr;

		input {
			@extend %generic-input
		}
	}

	h4 {
		margin-bottom: 10px;
		padding-bottom: 0px;
	}

	.invoice_void_buttons {
		text-align: right;

		button {
			display: inline-block;

			&.success {
				margin-left: 20px;
			}
		}
	}

	.record_payment_form {

		input,
		select {
			@extend %generic-input;
			height: 40px;
		}

		label {
			color: #666;
			margin-top: 15px;
			display: block;
		}
	}
}

.table_body {
	& .tr {
		& .td {
			&.price {
				display: flex;
				flex-direction: row;
				align-content: center;
				align-items: center;


				& input {

					width: 150px !important;
				}

			}
		}
	}
}

.table {
	&.payments_list {

		& .table_headings,
		.table_body .tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr
		}
	}
}