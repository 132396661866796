@import "utils/theme.scss";

form.search_form {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  height: 35px;

  input {
    padding: 10px;
    width: 100%;
    outline: none;
    border-radius: 7px;
    border: 0px;
    min-width: 250px;
    z-index: 1;
    background: transparent;

    &:focus {
      outline: 3px solid #e35f1cd6;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    right: 10px;
    position: absolute;
  }

  .search_results {
    position: absolute;
    top: 38px;
    left: 0px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    min-width: 100%;
    max-height: 250px;
    z-index: 9;
    overflow: auto;

    .result_item {
      display: block;
      padding: 10px;
      margin: 0;
      text-decoration: none;
      color: #444;
      font-weight: bold;
      cursor: pointer;
      transition: all .3s ease;
      border-bottom: 1px solid rgb(238, 238, 238);


      &:hover {
        background: #e35f1c3d;
      }
    }

    .direcional {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin: 10px 0;
      color: rgb(150, 150, 150)
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary-dim;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $secondary;
    }
  }
}