@import "../../utils/theme.scss";


#jobs_page_container{
    
    .jobs_page_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0px;

        input{
            @extend %generic-input;
            @extend %search-filter-input;
        }

        h2{
            margin-bottom: 0px;
        }
    }

    & .table{
        &.invoices{
            .table_headings, .tr{
                grid-template-columns:  0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr 1.3fr 1.3fr 0.7fr 0.7fr;
            }
            & .table_body{
                & .tr{
                    & .td{
                        &.overdue{
                            padding: 10px;
                            border-radius: 10px;
                            background: #ff0000;
                            color: #fff;  
                        }
                    }
                }
            }
        }
    }

    .load_more_invoices_btn{
        @extend %generic-button;
        display: block;
        margin: auto;
        margin-top: 20px;
    }
}

.button{
    cursor:pointer;
}
span.status{
    padding: 5px;
    border-radius: 10px;
    background: #999;
    color: #fff;

    &.VOID, &.unpaid,  &.cancelled, &.rejected{
        background-color: #e74c3c;
    }
    &.part_paid, &.closed{
        background-color: #e5b700;
    }
    &.open, .OPEN{
        background-color: #3498db;
    }
    &.paid, &.accepted, &.completed{
        background-color: #07bc0c;
    }
    
}

.slidein_right{
    animation: slideInRight .3s 1 ease;
}

@keyframes slideInRight {
    from{
        left: 100%;
        opacity: 0;
    }to{
        left: 0;
        opacity: 1;
    }
}