@import "utils/theme.scss";

#popup-container-main.activities_popup{
    .popup_contents_outer{
        max-width: 500px;

        .activity_item{
            display: flex;
            grid-gap: 10px;
            padding: 10px 0px;
            border-bottom: 1px solid #e6e6e6;
            transition: all 0.3s ease;
            
            &:hover{
                background-color: #f5f5f5;
            }

            div{
                width: 100%;

                span{
                    display: block;
                    text-align: right;
                    color: #666;
                    font-size: 12px;
                    margin-top: 5px;
                }

                p{
                    margin: 0px;
                }

            }
            svg{
                cursor: pointer;
            }
        }
    }
}