@import "utils/theme.scss";

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-radius: $dot-width * .5 !default;

$dot-color: #c0c0c0 !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

$dot-spacing: $dot-width + $dot-width * .5 !default;

$left-pos: -9999px;
$x1: - $left-pos - $dot-spacing;
$x2: - $left-pos;
$x3: - $left-pos + $dot-spacing;


@mixin dot(
  $width: $dot-width,
  $height: $dot-height,
  $radius: $dot-radius,
  $bg-color: $dot-bg-color,
  $color: $dot-color
) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bg-color;
  color: $color;
}

.dot-pulse {
  position: relative;
  left: $left-pos;

  @include dot;

  box-shadow: $x2 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: .25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;

    @include dot;
  }

  &::before {
    box-shadow: $x1 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: $x3 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: .5s;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: $x1 0 0 -5px;
  }

  30% {
    box-shadow: $x1 0 0 2px;
  }

  60%,
  100% {
    box-shadow: $x1 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: $x2 0 0 -5px;
  }

  30% {
    box-shadow: $x2 0 0 2px;
  }

  60%,
  100% {
    box-shadow: $x2 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: $x3 0 0 -5px;
  }

  30% {
    box-shadow: $x3 0 0 2px;
  }

  60%,
  100% {
    box-shadow: $x3 0 0 -5px;
  }
}