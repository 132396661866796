@import "utils/theme.scss";

hr{
  border:0px;
  border:1px solid #000;
}

.invoice_details_page{


  nav{
    display: block;
    background-color: $primary;
    padding: 10px 20px;

    .nav_inner{
      display: flex;
      align-items: center;
      color: $primary-dim;
      font-size: 30px;
      font-weight: bold;
      grid-gap: 10px;
      
      .logo{
        height: 45px;
      }

      span{
        color: #fff;
      }
    }
  }

  .invoice_details{
    max-width: 950px;
    margin: 0 auto;
    padding: 0 20px;
    line-height: 1.4;

    h3{
      margin: 0 0 10px;
    }

    .header_info{
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
    
      .company_info{
        display: flex;
        flex-direction: column;
      }

      .invoice_info > div{
        line-height: 1.6;
        font-size: 16px;
      }
    }
  }

  table.quoteitems{
  width:100%;
  }
  .detail_section{
    border: 2px solid $primary;
    background-color: #97cfe273;
    padding: 20px;
    margin: 20px 0;

    h4{
      margin: 0 0 10px;
    }



    .items_details{
      .items_table{
        width: 100%;
        text-align: left;
      }
    }

  }
  .terms_n_conditions{
    height: 150px;
    overflow: auto;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: small;
  }

  .approve_btns{
    margin: 20px 0;
    text-align: right;

    button{
      @extend %generic-button;
    }
  }
}


