@import "../../utils/theme.scss";


#quote_page_container{
    
    .quote_page_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0px;

        button{
            @extend %generic-button;
            background: $secondary-light;
        }
        input{
            @extend %generic-input;
            @extend %search-filter-input;
            height: 50px;
        }
    }

    div.table.quotes{
        .table_headings, .tr{
            grid-template-columns:  0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.3fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
        }
    }

}

.slidein_right{
    animation: slideInRight .3s 1 ease;
}

@keyframes slideInRight {
    from{
        left: 100%;
        opacity: 0;
    }to{
        left: 0;
        opacity: 1;
    }
}