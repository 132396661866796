@import "utils/theme.scss";


.inventory_page_header{
	display: flex;
	grid-gap: 15px;
	margin: 20px 0px;

	button{
		@extend %generic-button;
		background: $secondary-light
	}
}

.add_inventory_section{

	form{
		padding: 20px 15px;
		margin: 0 -15px;
		display: flex;
		grid-gap: 20px;
		align-items: flex-end;

		select, input{
			@extend %generic-input;
			margin-top: 5px;
		}
		label span{
			font-size: 13px;
			color: #999;
		}

		button{
			@extend %generic-button;
			height: 50px;
			display: flex;
		}

	}
}
.category_block{
	margin: 10px 3px 10px 15px;
	border: 2px solid #ccc;

	.inventory_items_table {
		margin: 10px 3px 10px 15px;

		.tr, .table_headings{
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			padding: 8px 10px;
		}
	}

	.category_content_block{
		.cat_title_container{
			background: #ccc;
			padding: 15px;
			display: flex;
			justify-content: space-between;

			h5{
				margin: 0;
			}
		}

	}
}