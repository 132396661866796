 /*Theme colors*/
 $primary: #1c2c42;
 $primary-light: #6d808c;
 $primary-dim: #6D808C;
 $secondary: #8F2700;
 $secondary-light: #3A9AB7;
 $secondary-dim: #6D808C;
 $light-black: #363537;

 %generic-button {
   border: 0;
   outline: none;
   padding: 10px 15px;
   border-radius: 7px;
   background: $primary-light;
   color: #fff;
   font-size: 18px;

   svg {
     width: 20px;
     height: auto;
     margin: 0 10px -4px 0;
   }
 }

 %generic-input {
   width: 100%;
   outline: 1px solid transparent;
   border: 1px solid #ddd;
   height: 50px;
   font-size: 18px;
   border-radius: 7px;
   padding: 10px 15px;
   transition: all .3s ease;

   &:focus,
   &:hover {
     outline: 2px solid $secondary-dim;
   }

   &[type="checkbox"],
   &[type="radio"] {

     &:hover,
     &:focus {
       outline: none;
     }
   }
 }

 %search-filter-input {
   height: 40px;
   font-size: 14px;
   max-width: 250px;
 }

 button.cancel {
   background-color: #e74c3c;
 }

 .form-control {
   &.error {
     border: 1px solid red;
   }
 }

 .form_button_wrapper {
   display: flex;
   justify-content: flex-end;
   gap: 20px;
   width: 100%;

   button {
     @extend %generic-button;
     min-width: 100px;
     margin-bottom: 10px;

     &:hover {
       background: $primary;
     }

     &.cancel {
       background-color: #e74c3c;
     }

     &.email {
       background-color: #79554A;
     }

     &.print {
       background-color: #03a9f4;
     }

     &.convert {
       background-color: #1BBC9A;
     }

   }
 }





 @media only screen and (max-width: 991px) {
   .invoice_details {
     & .header_info {
       flex-direction: column;
       gap: 20px;
     }
   }

   .invoice_details_page {
     & .table {

       &.quoteitems,
       &.payments {
         & .thead {
           display: none;
         }

         & .thead,
         & .tr,
         & .tbody .tr {
           grid-template-columns: 1fr;
           gap: 0px;
         }

         & .td {

           &.qty,
           &.net_amount,
           &.grand_total,
           &.tax_amount,
           &.total_amount {
             text-align: right;

             &:before {
               font-weight: bold;
             }
           }

           &.qty {
             &::before {
               content: "QTY: "
             }
           }

           &.total_amount {
             &::before {
               content: "Line Total: "
             }
           }

           &.net_amount {
             &::before {
               content: "Net: "
             }
           }

           &.tax_amount {
             &::before {
               content: "VAT: "
             }
           }

           &.grand_total {
             &::before {
               content: "Total Amount: "
             }
           }
         }
       }
     }
   }
 }