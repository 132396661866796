@import "utils/theme.scss";

#event_page_container{
	padding: 20px 0px;

	.event_page_header{
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.event_tab_btns{
			background-color: $primary-dim;
			border-radius: 7px;
			
			button{
				@extend %generic-button;
				background-color: transparent;
				padding: 10px 20px;
				// border-radius: 0;
			}
			.active_view{
				background-color: $primary;
			}
		}

		button.add_event_button{
			@extend %generic-button;
			background: $secondary-light;

			&:hover{
				background: $secondary;
			}
		}
	}

	
	.table{
  		.table_headings, .table_body .tr{
			  grid-template-columns: 1fr 1fr 1.2fr 1fr 1fr 1.2fr 1fr; 
			}
		}

		.calendar_view_selector{
			display: flex;
		justify-content: right;
		
		&>div{
			border: 1px solid #ccc;
			border-radius: 7px;
			margin-bottom: -30px;
			transform: translate(0px, 23px);
			background: #fff;
    		z-index: 99999;
			
			button{
				@extend %generic-button;
				background-color: transparent;
				color: #555;
				padding: 7px 15px;
				
				&.active{
					color: palevioletred;
				}
				
				&:hover{
					background-color: #ccc;;
				}
			}
		}
	}
}
div#popup-container-main.event_popup{
	z-index: 999999;
}

.Kalend__header_calendar_buttons__container{
	// .Kalend__DesktopLayout:nth-of-type(2n){
		display: none;
	// }
}