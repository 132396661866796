@import "utils/theme.scss";

#customer_page_container{
	padding: 20px 0px;

	.customer_page_header{
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		input{
			@extend %generic-input;
			@extend %search-filter-input;
		}

		button.add_customer_button{
			@extend %generic-button;
			background: $secondary-light;

			&:hover{
				background: $secondary;
			}
		}
	}
	.table{
  		.table_headings, .table_body .tr{
  			grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 1fr  1.5fr 1fr; 
  		}
  	}
}
