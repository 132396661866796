@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* fallback */


@import "utils/theme.scss";

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}


body {
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
  min-height: 100vh;
  font-size: 14px;
  align-items: center;
}

.cursor{
  cursor: pointer;
}

.nunito{
  font-family: nunito,sans-serif;
}

h1{
  font-size: 42px;
}
h2{
  font-size: 32px;
}
h3{
  font-size: 26px;
}
h4{
  font-size: 22px;
}
h5{
  font-size: 18px;
}
h6{
  font-size: 14px;
}

h1,h2,h3,h4,h5,h6{
  margin-top: 0px;
}
.cursor_pointer{
  cursor: pointer;
}
.form-control{
  @extend %generic-input;
}
.main_wrapper_container{
  display: flex;
  height: 100vh;

  &>.right_content{
    width: 100%;

    .content_container{
      padding: 0px 15px;
      overflow-y: auto;
      height: calc(100vh - 60px);

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #ccc;
      }
      &::-webkit-scrollbar-thumb {
        background: $primary-dim;
      }
      &::-webkit-scrollbar-thumb:hover{
        background: $primary;
      }
    }
  }
}

.pagePreloader, .preloader{
  display: flex;
  justify-content: center;
  align-items: center;
  width:  100vw;
  height:  100vh;
  & span{
     & .cssfx-bar-wave{
      & span{
        background-color:$primary
      }
     }
  }
}

.pagePreloader-half, .preloader-half{
  display: flex;
  justify-content: center;
  align-items: center;
  width:  100vw;
  height:  30vh;
}

.preloader{
  width: 100%;
  height: 100%;
}

.popuploading{
  position: absolute;
  width: 100%;
  display: flex;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.9);
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

button{
  cursor: pointer;
}

*[disabled]{
  cursor: not-allowed;
}


.table{
  &.clickable{
    & tbody{
      & tr{
        &:hover{
          cursor:pointer;
        }
      }
    }
  }
  & thead{
    &:after{
      border-bottom:1px solid #000;
      content:" ";
    }
    & th{
      text-align:left;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  & tbody{
    & tr{
      & td{
        padding: 5px;
      }
      &:hover{
        background-color: #eaeaea;
      }
    }
  }

  .table_headings, .table_body .tr{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    padding: 10px;

    .th.sortable_th{
      user-select: none;
      cursor: pointer;
    }
  }

  .table_body .tr{
    transition: all .3s ease;

    &:nth-of-type(even){
      background: #97cfe25e;
    }

    &:hover{
      background: #e35f1c3d;
    }
  }

  .table_headings{
    font-weight: bold;
    background: $primary;
    padding: 15px 10px;
    color: #fff;
  }
}

button.icon_btns{
  border: 0px;
  outline: none;
  background: transparent;
  padding: 0;
  line-height: 1;

  svg{
    fill: $secondary;
    width: 20px;
    height: auto;
    margin-left: 15px;
  }
}