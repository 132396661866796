@import '../../utils/theme.scss';

.quote_details_page {
  padding-top: 20px;
  // padding:20px; // causes h scrolling

  & .approve_btns {
    text-align: right;
  }
  & .questions {
    padding: 0px;
    padding-top: 20px;
    margin: 0px;
    & li {
      font-weight: bold;
      margin-bottom: 20px;
      list-style-type: none;
    }
    & textarea {
      width: 100%;
      padding: 10px;
    }
  }
  nav {
    display: block;
    background-color: $primary;
    padding: 10px 20px;

    .nav_inner {
      display: flex;
      align-items: center;
      color: $primary-dim;
      font-size: 30px;
      font-weight: bold;
      grid-gap: 10px;

      .logo {
        height: 45px;
      }

      span {
        color: #fff;
      }
    }
  }

  .content_container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.4;
    padding: 0px;

    h3 {
      margin: 0 0 10px;
    }

    .header_info {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;

      .company_info {
        display: flex;
        flex-direction: column;
      }

      .quote_info > div {
        line-height: 1.6;
        font-size: 16px;
      }
    }
  }

  .detail_section {
    border: 2px solid $primary;
    color: $primary;
    padding: 20px;
    margin: 20px 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    & table.mobilequoteitems {
      display: none;
    }

    & .fullwidth {
      width: 100%;
    }

    h4 {
      font-size: 17px;
      margin: 0 0 10px;
    }

    .customer_details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .items_details {
      width: 100%;

      & .quoteitems {
        width: 100%;
      }

      .items_table {
        width: 100%;
        text-align: left;

        & tr {
          border-bottom: 1px solid #c0c0c0;
          margin-bottom: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }

  .terms_n_conditions {
    height: 150px;
    overflow: auto;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: small;
  }

  button {
    @extend %generic-button;
  }
}

@media only screen and (max-width: 600px) {
  .quote_details_page {
    & .nav_inner {
      justify-content: center;
    }

    & .quote_details {
      & .header_info {
        display: block;

        & .company_info,
        & .quote_info {
          text-align: center;
        }
      }
    }

    & .quote_details {
      text-align: center;
    }

    & .detail_section {
      & table.mobilequoteitems {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        & hr {
          width: 100%;
        }

        & tr {
          & td {
            vertical-align: top;
            display: table-cell;
            text-align: left;
            width: 50%;

            &.label {
              font-weight: bold;
              text-align: left;
              width: 50%;
            }
          }
        }
      }

      display: block;

      & div {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    & .items_details {
      & table.quoteitems {
        display: none;
      }
    }
  }
}
